<template>
  <div class="">
    <h3 class="font-weight-light mb-3">Ключевые этапы</h3>
    <p>
      Составьте краткий список важных этапов развития Вашего бизнеса. Например: запуск нового продукта, выход на новый рынок, найм 
      ключевого сотрудника или поиск подходящего места для нового магазина.
    </p>
    <p>
      Список может содержать столько этапов, сколько Вы сами определите. Однако имейте ввиду, что тут будут представлены 
      только ближайшие пять этапов.
    </p>

    <b-row class="mt-4">
      <b-col sm="12">
        <milestones-renderer />
      </b-col>
      
      <b-col sm="12" class="d-flex mt-4">
        <div class="btn btn-outline-primary" 
          v-b-modal.milestoneEditModal>
          Добавить этап
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MilestonesRenderer from '../../../components/app/widgets/MilestonesRenderer';

export default {
   metaInfo: {

  },

  data: () => ({
    selected: 'desc',
    options: [
      { text: 'Описание', value: 'desc' },
      { text: 'Список', value: 'list' }
    ],
    desc: '',
    list:[
      { index: 0, text: '' },
      { index: 1, text: '' },
      { index: 2, text: '' },
      { index: 3, text: '' },
      { index: 4, text: '' },
    ],
    displayCount: null
  }),

  components: {
    MilestonesRenderer
  },

  computed: {
    textCount() {
      return this.desc.length;
    }
  },

  methods: {
    formatter(value) {
      return value.slice(0, 40)
    }
  }
  

}
</script>

<style lang="scss" scoped>

  .list-group-item {
    min-height: 2rem;
    .list-input {
      border: none;
      border-radius: 0;
      border-bottom: 1px dashed #344050;
      &:focus {
        outline: none !important;
        box-shadow: none;
        border-bottom: 1px solid #2c7be5 ;
      }
    }

    .list-input__count {
      position: absolute;
      right: 20px;
      top: 12px;
    }
 
  }

</style>>

