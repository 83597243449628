<template>
  <div class="">
      <h3 class="font-weight-light">Краткое описание</h3>
      <p>Начните с сильного, убедительного описания Вашей компании и ее бизнес-возможностей. Каковы основные элементы Вашего успеха? 
        Вы предлагаете инновационный продукт? Возможно Вы обнаружили часть рынка, которая еще никем не охвачена? 
        А возоможно у Вас сильная и проверенная в делах управленческая команда, которым по силам любые бизнес-проекты? 
        Текст должен быть не более 140 символов, поэтому сосредоточьтесь только на самом важном.</p>

      <b-row class="mt-4">
        <b-col sm="12">
          <label for="description">
            <strong>Опшите свою компанию в одном предложении:</strong>
          </label>
          <b-form-textarea
            id="description"
            v-model="desc"
            placeholder="Коротко и понятно..."
            :state="(desc.length > 140 ? false : null)"
            rows="4"
            max-rows="3">
          </b-form-textarea>
          <small class="text-300" style="position: relative; left: 1.2rem; top: -1.5rem;">
            {{textCount}} из 140 символов
          </small>
        </b-col>

      </b-row>
  </div>
</template>

<script>


export default {
   metaInfo: {

  },

  data: () => ({

  }),

  components: {
  },

  mounted() {
  },

  computed: {
    desc: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.company.desc;
        } else {
          return '';
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchCompanyDesc', newValue);
      }
    },

    titleState() {
      return this.companyTitle.length >= 4 ? true : false
    },

    invalidFeedback() {
      if (this.companyTitle.length >= 4) {
        return ''
      } else if (this.companyTitle.length > 0) {
        return 'Название должно быть не менее 4-х символов'
      } else {
        return 'Пожалуйства, введине название...'
      }
    },

    validFeedback() {
      return this.state === true ? 'Отлично!' : ''
    },

    textCount() {
      return this.desc.length;
    }
    
    

  },

  methods: {
    setImagePreview(file) {  
      if (!file) {
        return null;
      }

      if (file.type && file.type.indexOf('image') === -1) {
        this.companyLogo = null;
        this.imagePreview = null;
        return null
      } 
      const reader = new FileReader();
      reader.addEventListener('load', event => {
        this.imagePreview = event.target.result;
      });
      reader.readAsDataURL(file); 
    }

  }
  

}
</script>

<style lang="scss" scoped>
  .dropzone {
    background: inherit;
  }

  .image-preview {
    position: absolute;
    width: 95%;
    min-height: 98%;
    top: 1%;
    left: 2.5%;
    background-size: contain;
    background-position: center;
    overflow: hidden;
    will-change: transform, opacity, filter;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-repeat: no-repeat;
    z-index: 0;
  }
</style>>

