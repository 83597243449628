<template>
  <div class="">
    <h3 class="font-weight-light mb-3">Маркетинговая стратегия</h3>
    <p>
      Объясните, как Вы планируете привлечь внимание, вызвать интерес к Вашей продукции (услуге) и превратить потенциальных клиентов в покупателей. 
      Если Вы планируете пресутсвововать в сети Интернет, то опишите какой вид интернет-маркетинга Вы планируете привлекать. 
      Вы планируете размещать рекламу в Интернете, в печатных изданиях или в других рекламных источниках? 
      Планируете ли Вы посещать торговые выставки или спонсорские мероприятия? 
    </p>

    <b-row class="mt-4">
      <b-col sm="12">
        <h5 class="font-weight-normal mb-3">
          Можно описать Вашу меркетинговую стратегию или составить список маркетинговых активноcтей
        </h5>
        <b-form-group>
          <b-form-radio-group
            v-model="selected"
            :options="options"
            name="problem-type">
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col v-if="selected === 'desc'" 
        sm="12"
        class="mt-2">
        <label for="description" class="ml-1">
          <strong>Опишите маректинговую стратегию:</strong>
        </label>
        <b-form-textarea
          id="description"
          v-model="desc"
          placeholder="Коротко и понятно..."
          :state="(desc.length > 250 ? false : null)"
          rows="4"
          max-rows="3">
        </b-form-textarea>
        <small class="text-300" style="position: relative; left: 1.2rem; top: -1.5rem;">
          {{textCount}} из 250 символов
        </small>
      </b-col>
      <b-col v-if="selected === 'list'"
        sm="8"
        class="mt-3">
        <ul class="list-group list-group-flush mb-4">
          <li v-for="(item, index) in list" :key="item.index"
            class="d-flex align-content-center list-group-item pb-0 pt-1 bg-200">
            <span :class="{'text-300': item.text == ''}"
              style="line-height: 2rem; width: 30px;">{{item.index + 1}}.</span>
            <b-form-input
              class="list-input p-0 bg-200"
              :disabled="(index != 0) && !list[index-1].text"
              v-model="list[index].text"
              @click="displayCount = index"
              @blur="displayCount = null"
              :formatter="formatter"
              trim>
            </b-form-input>
            <span v-if="index == displayCount"
              class="badge badge-soft-secondary list-input__count">
              {{40 - list[index].text.length}}
            </span>
          </li>
        </ul>
      </b-col>

    </b-row>
  </div>
</template>

<script>

export default {
   metaInfo: {

  },

  data: () => ({
    options: [
      { text: 'Описание', value: 'desc' },
      { text: 'Список активностей', value: 'list' }
    ],
    displayCount: null
  }),

  computed: {

    selected: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.marketing.selectedType;
        } else {
          return 'desc';
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchMarketingSelectedType', newValue);
      }
    },

    desc: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.marketing.desc;
        } else {
          return '';
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchMarketingDesc', newValue);
      }
    },

    list: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.marketing.list;
        } else {
          return [
            { index: 0, text: '' },
            { index: 1, text: '' },
            { index: 2, text: '' },
            { index: 3, text: '' },
            { index: 4, text: '' },
          ];
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchMarketingList', newValue);
      }
    },

    textCount() {
      return this.desc.length;
    }
  },

  methods: {
    formatter(value) {
      return value.slice(0, 40)
    }
  }
  

}
</script>

<style lang="scss" scoped>

  .list-group-item {
    min-height: 2rem;
    .list-input {
      border: none;
      border-radius: 0;
      border-bottom: 1px dashed #344050;
      &:focus {
        outline: none !important;
        box-shadow: none;
        border-bottom: 1px solid #2c7be5 ;
      }
    }

    .list-input__count {
      position: absolute;
      right: 20px;
      top: 12px;
    }
 
  }

</style>>

