<template>
  <div class="">
    <h3 class="font-weight-light mb-3">Конкурентное окружение</h3>
    <p>
      Составьте короткий список Ваших конкурентов. Если у вас нет явных прямых конкурентов, то задумайтесь, какие еще способы существуют для удовлетворения потребностей 
      Ваших целевых клиентов. Например, у Вас может быть единственный боулинг в городе, но Вам все же приходится конкурировать с другими вариантами развлечений.  
      Вместо посещения боулинга, потенциальные клиенты могли бы пойти в кинотеатр, ресторан или на местный каток. При дабовлении конкурентов, опишите, 
      что делает Ваше предложение более привлекательным. Возможено, Вы предлагаете более низкую цену? Или уникальный опыт? А может быть расположены в более выгодном месте?
    </p>

    <b-row class="mt-4">
      <b-col sm="12" class="mb-3">
        <div class="card mb-3">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-4">
                  <strong>Название конкурента</strong>
                </div>
                <div class="col-6">
                  <strong>Преимущества Вашего решения</strong>
                </div>
                <div class="col-auto ml-auto">
                  <div v-b-modal.competitorEdit
                    class="btn btn-outline-primary btn-sm"
                    @click="newCompetitor">
                    <svg class="svg-inline--fa fa-plus fa-w-14 mr-1" 
                      data-fa-transform="shrink-3" 
                      aria-hidden="true" 
                      focusable="false" 
                      data-prefix="fas" 
                      data-icon="plus" 
                      role="img" 
                      xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 448 512" 
                      data-fa-i2svg="" 
                      style="transform-origin: 0.4375em 0.5em;">
                      <g transform="translate(224 256)">
                        <g transform="translate(0, 0)  scale(0.8125, 0.8125)  rotate(0 0 0)">
                          <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" transform="translate(-224 -256)">
                          </path>
                        </g>
                      </g>
                    </svg>
                    Добавить
                  </div>
                </div>
              </div>
            </div>
            <draggable 
              class="card-body fs--1 border-top border-200 p-0" id="competitors"
              :list="competitorsList"
              @change="moveItems">
              <div v-for="(item, index) in competitorsList" :key="item.index" 
                class="row border-bottom border-200 hover-actions-trigger hover-shadow py-2 px-1 mx-0">
                <div class="btn-group btn-group-sm hover-actions r-0 mr-3">
                  <button class="btn btn-outline-light border-0" 
                    type="button" 
                    data-toggle="tooltip" 
                    data-placement="top" 
                    title="" 
                    data-original-title="Редактировать"
                    v-b-modal.competitorEdit
                    @click="editCompetitor(item)">
                    <span class="fas fa-pencil-alt"></span>
                  </button>
                  <button class="btn btn-outline-danger border-0" 
                    type="button" 
                    data-toggle="tooltip" 
                    data-placement="top" 
                    title="" 
                    data-original-title="Удалить"
                    @click="deleteCompetitor(index)">
                    <svg class="svg-inline--fa fa-trash-alt fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path>
                    </svg>
                    <!-- <span class="fas fa-trash-alt"></span> -->
                  </button>
                </div>
                <div class="col-4">
                  
                  <h6><small class="text-600">{{index + 1}}. </small> {{item.title}}</h6>
                </div>
                <div class="col-8">
                  <div class="btn-sm px-2 border-0 hover-actions move-arrow text-300"
                    style="position: absolute; left: -20px;">
                    <i class="fas fa-arrows-alt-v"></i>
                  </div>
                  <span>{{item.desc}}</span>
                </div>
              </div>
            </draggable>
            
          </div>
      </b-col>
    </b-row>
    
    <!-- EDIT MODAL  -->
    <competitor-edit-modal />

  </div>
</template>

<script>
import draggable from "vuedraggable"

import CompetitorEditModal from '@/components/app/modals/PitchCompetitorEdit';

export default {
   metaInfo: {

  },

  data: () => ({

  }),

  components: {
    draggable,
    CompetitorEditModal
  },

  computed: {

    competitorsList: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.competitors.list;
        } else {
          return [];
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchCompetitorsList', newValue);
      }
    },

    textCount() {
      return this.desc.length;
    }
  },

  methods: {
    
    moveItems() {
      this.competitorsList.map((item, index) => {
        item.index = index;
      });
    },

    newCompetitor() {
      this.editCompetitor(null);
    },

    editCompetitor(competitor) {
      this.$store.commit('setPitchCompetitorToEdit', competitor);
    },

    deleteCompetitor(index) {
      this.$store.commit('deletePitchCompetitorsListItem', index);
    }
  }
  

}
</script>

<style lang="scss" scoped>

  .list-group-item {
    min-height: 2rem;
    .list-input {
      border: none;
      border-radius: 0;
      border-bottom: 1px dashed #344050;
      &:focus {
        outline: none !important;
        box-shadow: none;
        border-bottom: 1px solid #2c7be5 ;
      }
    }

    
 
  }

</style>>

