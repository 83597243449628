<template>
   <div>
    <b-modal
      id="marketSegmentEdit" 
      header-class="pb-2"
      hide-footer
      no-stacking
      v-model="showModal">

      <template slot="modal-title">
        <div class="row mt-2">
            <div class="col text-left ml-2" style="min-width: 7rem">
              <h4 id="modalLabel" class="text-primary">
                Рыночный сегмент
                <small class="text-500">основные данные</small>
              </h4>
            </div>              
          </div>
      </template>

      <b-row class="mt-2">
          <b-col sm="12">
            <b-form-group
              class="ml-2 mr-2" 
              label="Наименование сегмента"
              labele-for="segmentTitle" 
              :invalid-feedback="invalidFeedback"
              :valid-feedback="validFeedback"
              :state="titleState">
              <b-form-input
                id="segmentTitle"
                v-model="marketSegment.title"
                :state="titleState">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="7">
            <label class="ml-1"
              for="prospectus">
              Cколько потециальных клиентов в этом сегменте?
            </label>
          </b-col>
          <b-col sm="5">
            <b-form-input
              class="mt-1 text-right"
              style="max-width: 200px;" 
              id="prospectus"
              type="number"
              v-model="marketSegment.prospectus"
              :state="prospectusState">
            </b-form-input>
          </b-col>
          <b-col sm="7">
            <label class="ml-1"
              for="spendings">
              Cколько потециальный клиент тратит в год, на решаемую Вами проблему?
            </label>
          </b-col>
          <b-col sm="5">
            <b-input-group
              class="mt-1"   
              append="₽">
              <b-form-input 
                id="spendings"
                v-model="marketSegment.spendings"
                class="text-right"
                type="number"
                :formatter="value => value < 0 ? 0 : value">
              </b-form-input>
            </b-input-group>
          </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col sm="12">
         
        </b-col>
      </b-row>
      
      <b-row >
        <b-col sm="12" class="d-flex">
          <b-button class="mt-3 mr-3 ml-2" @click.prevent="showModal = false">
            Отмена
          </b-button>
          <b-button 
            v-if="loadData"
            class="mt-3 mr-2"
            variant="primary"
            block 
            disabled>
            <b-spinner small type="grow"></b-spinner>
            Сохраняем...
          </b-button>
          <b-button 
            v-if="!loadData"
            class="mt-3 mr-2" 
            variant="primary" 
            block 
            @click="createMarketSegment">
            Сохранить!
          </b-button>
        </b-col>
      </b-row>
      
    </b-modal>

  </div>
</template>

<script>

export default {
  data: () => ({
    showModal: false,
    segmentTitle: '',
    prospectus: 0,
    spendings: 0,
    loadData: false,
  }),

  computed: {

    marketSegment() {
      const itemToEdit = this.$store.getters.getMarketChartSegmentToEdit;
      if (itemToEdit) {
        return itemToEdit
      } else {
        return { title: '', prospectus: 0, spendings: 0 }
      }
       
    },

    titleState() {
      return this.marketSegment.title.length >= 4 ? true : false
    },

    prospectusState() {
      return this.marketSegment.prospectus > 0 ? true : false
    },

    invalidFeedback() {
      if (this.marketSegment.title.length >= 4) {
        return ''
      } else if (this.marketSegment.title.length > 0) {
        return 'Название должно быть не менее 4-х символов'
      } else {
        return 'Пожалуйства, введине наименование...'
      }
    },
    validFeedback() {
      return this.state === true ? 'Отлично!' : ''
    },
  },

  methods: {
    createMarketSegment() {
      
      if (!this.titleState && !this.prospectusState) {
        return
      }

      this.marketSegment.prospectus = parseInt(this.marketSegment.prospectus);
      this.marketSegment.spendings = parseInt(this.marketSegment.spendings);
      this.marketSegment.totalSpendings = (this.marketSegment.prospectus * this.marketSegment.spendings);
      
      console.log( this.marketSegment);

      this.$store.commit('addPitchMarketChartSegment', this.marketSegment);
      this.showModal = false;
    }

  }

}
</script>

<style lang="scss" scoped>
  .income-title {
    line-height: 90%; 
    margin-top: 2px; 
    margin-bottom: 0.5rem;
  }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
</style>