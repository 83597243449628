<template>
  <div class="">
    <h3 class="font-weight-light">Целевой рынок</h3>
    <p>
      На каких сегментах потенциального рынка Вы намерены сосредоточиться? 
      Какой сегмент представляет Ваш первичный интерес? За какими также стоит гнаться? 
      Продажа “всем" - не очень хорошая идея. Возьмем Nike в качестве примера. 
      Их обувь в целом удобная, но это не значит, что их целевой рынок - это просто 
      “люди с ногами". Всегда очень трудно создавать маркетинговые сообщения и находить каналы продаж, 
      чтобы обратиться почти ко всем в мире одновременно. Вместо этого Nike фокусируется 
      на своих лучших потенциальных клиентах. Нацеливая, скажем, новые Air Jordans на 
      спортсменов, любителей спорта, молодых людей со спортивным чувством стиля и так далее.
    </p>
    <p>
      Когда Вы определяете наиболее интересный рынок, предположите, 
      сколько потенциальных клиентов доступно в каждом из сегментов и 
      сколько они обычно тратят каждый год на решение проблемы, которую Вы собираетесь решать. 
      Если у Вас нет достаточно данный для составления этих оценок, то это отличная возможность 
      провести маркетинговое исследование, поговорить со знающими людьми Вашей отрасли и 
      вступить в разговор с потенциальными клиентами. Чем больше Вы узнаете о своем рынке сейчас, 
      тем больше шансов преуспеть.
    </p>

    <b-row class="mt-4">
      <b-col sm="12">
        <h5 class="font-weight-normal mb-3">
          Можно создать график сегментов целевого рынка или составить их список?
        </h5>
        <b-form-group>
          <b-form-radio-group
            v-model="selected"
            :options="options"
            name="problem-type">
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      
      <b-col v-if="selected === 'chart'" 
        sm="12"
        class="mt-4">
        <b-row class="d-flex align-items-center p-0">
          <div style="width: 230px;">
            <button v-b-modal.marketSegmentEdit 
              class="btn btn-outline-primary btn-sm p-0 chart_add-btn"
              style="position: absolute; width: 25px; height: 25px; line-height: 22px; left: 10px; z-index: 10;"
              :disabled="chartSections.length > 4"
              @click="newMarketSegment">
              <i class="fas fa-plus"></i>
            </button>
            <market-chart 
              :chartData="marketChartData"
              :prospectus="totalMarket.prospectus" 
              :marketSize="totalMarket.marketSize" 
            />
          </div>
          <div class="flex-fill">
            <div v-for="(item, index) in chartSections" :key="item.index"
              class="d-flex align-items-center ml-4">
              <div class="chart-legend_point mr-3"
                :class="`point${index+1}`">

              </div>
              <div class="chart-legend_title">
                <strong class="mr-2">
                  {{item.title}}
                </strong>
                <small> 
                  {{marketShare(item.spendings) | numeralFormat('0.0%')}} 
                  (₽ {{ item.spendings | numeralFormat('(0a)') }})
                </small>
              </div>
              <div class="chart-legend_edit ml-auto mr-5 align-items-center">
                <div class="btn btn-outline-secondary btn-sm px-2 border-0"
                  v-b-modal.marketSegmentEdit
                  @click="editMarketSegment(item)">
                  <i class="fas fa-pencil-alt"></i>
                </div>
                <span class="text-100 fs-2 mx-1">|</span>
                <div class="btn btn-outline-danger btn-sm px-2 border-0"
                  @click="deleteMarketChartSegment(index)">
                  <i class="far fa-trash-alt"></i>
                </div>
              </div>
            </div>
     
          </div>
        </b-row>
      </b-col>

      <b-col v-if="selected === 'list'"
        sm="8"
        class="mt-2">
        <label for="description">
          <strong>Опшите сегменты Вашего целевого рынка (по приоритету):</strong>
        </label>
        <ul class="list-group list-group-flush mb-4">
          <li v-for="(item, index) in list" :key="item.index"
            class="d-flex align-content-center list-group-item pb-0 pt-1 bg-200">
            <span :class="{'text-300': item.text == ''}"
              style="line-height: 2rem; width: 30px;">{{item.index + 1}}.</span>
            <b-form-input
              class="list-input p-0 bg-200"
              :disabled="(index != 0) && !list[index-1].text"
              v-model="list[index].text"
              @click="displayCount = index"
              @blur="displayCount = null"
              :formatter="formatter"
              trim>
            </b-form-input>
            <span v-if="index == displayCount"
              class="badge badge-soft-secondary list-input__count">
              {{40 - list[index].text.length}}
            </span>
          </li>
        </ul>
      </b-col>

    </b-row>

    <!-- ADD MODAL -->
    <market-segment-edit-modal />
  </div>
</template>

<script>
import MarketChart from '@/components/app/charts/MarketDonut';
import MarketSegmentEditModal from '@/components/app/modals/PitchMarketSegmentEdit';

export default {
   metaInfo: {

  },

  data: () => ({
    options: [
      { text: 'График', value: 'chart' },
      { text: 'Список сегментов', value: 'list' }
    ],
    displayCount: null,
  }),

  components: {
    MarketChart,
    MarketSegmentEditModal
  },

  computed: {
    selected: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.market.selectedType;
        } else {
          return 'chart';
        }
      },
      set: function(newValue) {
        console.log(newValue)
        this.$store.commit('setPitchMarketSelectedType', newValue);
      }
    },

    chartSections: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.market.chart.sort(function(a, b) {
            return b.spendings - a.spendings; // сортируем по убыванию
          });
        } else {
          return [];
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchMarketChartSegment', newValue);
      }
    },

    list: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.market.list;
        } else {
          return [
            { index: 0, text: '' },
            { index: 1, text: '' },
            { index: 2, text: '' },
            { index: 3, text: '' },
            { index: 4, text: '' },
          ];
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchMarketList', newValue);
      }
    },

    textCount() {
      return this.desc.length;
    },

    totalMarket() {
      var totalMarket = {prospectus: 0, marketSize: 0}
      this.chartSections.map(item => {
        totalMarket.prospectus += item.prospectus;
        totalMarket.marketSize += (item.spendings * item.prospectus);
      });

      return totalMarket
    },

    marketChartData() {
      var chartData = []
      chartData = this.chartSections.map(item => {
        return {
          name: item.title, 
          y: item.spendings
        }
      });

      return chartData
    }
  },

  methods: {
    formatter(value) {
      return value.slice(0, 40)
    },

    marketShare(amount) {
      return (amount / this.totalMarket.marketSize)
    },

    newMarketSegment() {
      this.editMarketSegment();
    },

    editMarketSegment(marketSegment) {
      this.$store.commit('setMarketChartSegmentToEdit', marketSegment);
    },

    deleteMarketChartSegment(index) {
      this.$store.commit('deleteMarcketChartSegment', index);
    }
  }
  

}
</script>

<style lang="scss" scoped>

  .list-group-item {
    min-height: 2rem;
    .list-input {
      border: none;
      border-radius: 0;
      border-bottom: 1px dashed #344050;
      &:focus {
        outline: none !important;
        box-shadow: none;
        border-bottom: 1px solid #2c7be5 ;
      }
    }

    .list-input__count {
      position: absolute;
      right: 20px;
      top: 12px;
    }
  }

  .chart-legend_point {
    display: -moz-inline-box;
    width: 15px;
    height: 15px !important;
    border-radius: 3px;
    &.point1 {
      background: #0a93cc;
    }
    &.point2 {
      background: #f8d054;
    }
    &.point3 {
      background: #11b70b;
    }
    &.point4 {
      background: #eda027;
    }
    &.point5 {
      background: #8378c7;
    }
  }

  .chart-legend_point {
    display: -moz-inline-box;
    height: 30px;
  }

</style>>

