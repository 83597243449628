<template>
  <div class="">
    <h3 class="font-weight-light mb-3">Финансовый прогноз</h3>
    <p>
      Когда были определены основные элементы: рынок (совокупность потенциальных клиентов) с насущной проблемой, 
      которую стоит решить, пришло время составить предварительный финансовый прогноз Вашего решения. 
      На самом ли деле, Вы в состоянии получить прибыль? Т.к. очень часто, на первый взгляд некоторые возможности выглядят хорошо, 
      но не выдерживают проверки финансовым анализом, после которого становится понятно, что игра не стоит свеч. 
      Если Вы разрабатывете план длу существующей компании, то определение финансовых целей так же очень важный этап, 
      это позволит Вам отслеживать прогресс в дальнейшем.
    </p>

    <b-row class="mt-4">
      <b-col sm="12">
        <h5 class="font-weight-normal mb-3">
          Какой тип прогноза будем использовать?
        </h5>
        <b-form-group>
          <b-form-radio-group
            v-model="firecastType"
            :options="forecastTypeOptions"
            name="forecast-type">
          </b-form-radio-group>
        </b-form-group>
        <hr>
      </b-col>

      <!-- Отображаем графики -->
      <keep-alive>
        <b-col sm="12"  v-if="firecastType == 'chart'">
          
          <pitch-chart-renderer />

          <b-col sm="12">
            <h5 class="font-weight-normal my-3">
              Подготовка финансового прогноза
            </h5>
            <p>
              Перейдите на вкладку <router-link to="/finances">ФИНАНСЫ</router-link>, для подготовки или изменения данных финансового прогноза. 
              После внесения информации о доходах и расхода, актуальная информация появится в этом разделе.
            </p>
          </b-col>

        </b-col>
      </keep-alive>
      
      <!-- Отображаем описание -->

      <b-col v-if="firecastType === 'desc'" 
        sm="12" class="mt-2">
        <h5 class="font-weight-normal mb-3">
          Источники выручки
        </h5>
        <p>
          Если Вы не готовы подготовить полноценный прогноз доходов (или не хотите раскрывать данные в своей призентации), 
          используйте эту область, чтобы объяснить, как Ваша компания планирует зарабатывать деньги. 
          Вы собираетесь продавать (оказывать услуги) напрямую? Или через третьих лиц? 
          А возможно Ваш доход зависит от партнерских отношений, роялти или других соглашений?
        </p>
        <b-form-group>
          <b-form-radio-group
            class="mt-2"
            v-model="revenue"
            :options="revenueOptions"
            name="revenue-type">
          </b-form-radio-group>
        </b-form-group>
        <b-col v-if="revenue == 'revenueDesc'" 
          sm="12" class="p-0">
          <label for="description" class="ml-1">
            <strong>Опишите Ваши источники выручки:</strong>
          </label>
          <b-form-textarea 
            id="revenue"
            v-model="revenueDesc"
            placeholder="Коротко и понятно..."
            :state="(revenueDesc.length > 250 ? false : null)"
            rows="4"
            max-rows="3">
          </b-form-textarea>
          <small class="text-300" style="position: relative; left: 1.2rem; top: -1.5rem;">
            {{revenueDesc.length}} из 250 символов
          </small>
        </b-col>
        <b-col v-if="revenue == 'revenueList'"
          sm="8"
          class="mt-3">
          <ul class="list-group list-group-flush mb-4">
            <li v-for="(item, index) in revenueList" :key="item.index"
              class="d-flex align-content-center list-group-item pb-0 pt-1 bg-200">
              <span :class="{'text-300': item.text == ''}"
                style="line-height: 2rem; width: 30px;">{{item.index + 1}}.</span>
              <b-form-input
                class="list-input p-0 bg-200"
                :disabled="(index != 0) && !revenueList[index-1].text"
                v-model="revenueList[index].text"
                @click="revenueListDisplayCount = index"
                @blur="revenueListDisplayCount = null"
                :formatter="value => value.slice(0, 40)"
                trim>
              </b-form-input>
              <span v-if="index == revenueListDisplayCount"
                class="badge badge-soft-secondary list-input__count">
                {{40 - revenueList[index].text.length}}
              </span>
            </li>
          </ul>
        </b-col>
        
        <h5 class="font-weight-normal mb-3">
          Основные затраты
        </h5>
        <p>
          Используйте эту область для раскрытия структуры затрат Вашего бизнеса. 
          Каковы Ваши основные затраты на ведение бизнеса? 
          Как будут увеличиваться расходы по мере роста компании? 
          Нужно ли Вам выделять бюджет на закупку основных средств, имущества или других крупных покупок?
        </p>
        <b-form-group>
          <b-form-radio-group
            class="mt-2"
            v-model="expns"
            :options="expnsOptions"
            name="expns-type">
          </b-form-radio-group>
        </b-form-group>
        <b-col v-if="expns == 'expnsDesc'" 
          sm="12" class="p-0">
          <label for="description" class="ml-1">
            <strong>Опишите Ваши источники выручки:</strong>
          </label>
          <b-form-textarea 
            id="expenses"
            v-model="expnsDesc"
            placeholder="Коротко и понятно..."
            :state="(expnsDesc.length > 250 ? false : null)"
            rows="4"
            max-rows="3">
          </b-form-textarea>
          <small class="text-300" style="position: relative; left: 1.2rem; top: -1.5rem;">
            {{expnsDesc.length}} из 250 символов
          </small>
        </b-col>
        <b-col v-if="expns == 'expnsList'"
          sm="8"
          class="mt-3">
          <ul class="list-group list-group-flush mb-4">
            <li v-for="(item, index) in expnsList" :key="item.index"
              class="d-flex align-content-center list-group-item pb-0 pt-1 bg-200">
              <span :class="{'text-300': item.text == ''}"
                style="line-height: 2rem; width: 30px;">{{item.index + 1}}.</span>
              <b-form-input
                class="list-input p-0 bg-200"
                :disabled="(index != 0) && !expnsList[index-1].text"
                v-model="expnsList[index].text"
                @click="expnsListDisplayCount = index"
                @blur="expnsListDisplayCount = null"
                :formatter="value => value.slice(0, 40)"
                trim>
              </b-form-input>
              <span v-if="index == expnsListDisplayCount"
                class="badge badge-soft-secondary list-input__count">
                {{40 - expnsList[index].text.length}}
              </span>
            </li>
          </ul>
        </b-col>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import PitchChartRenderer from '@/components/app/charts/PitchChartRenderer';

export default {
   metaInfo: {

  },

  data: () => ({
    forecastTypeOptions: [
      { text: 'Описание или список пунктов', value: 'desc' },
      { text: 'Графики и данные', value: 'chart' }
    ],
    revenueOptions: [
      { text: 'Описание', value: 'revenueDesc' },
      { text: 'Короткий список', value: 'revenueList' }
    ],
    expnsOptions: [
      { text: 'Описание', value: 'expnsDesc' },
      { text: 'Короткий список', value: 'expnsList' }
    ],
    revenueListDisplayCount: null,
    expnsListDisplayCount: null
  }),

  components: {
    PitchChartRenderer
  },

  computed: {

    firecastType: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.forecast.selectedType;
        } else {
          return 'desc';
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchForecastSelectedType', newValue);
      }
    },

    revenue: { // Переключатель типа вводимой информации по выручке
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.forecast.desc.revenueSelected;
        } else {
          return 'revenueDesc';
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchForecastRevenueSelectedType', newValue);
      }
    },

    revenueDesc: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.forecast.desc.revenueDesc;
        } else {
          return '';
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchForecastRevenueDesc', newValue);
      }
    },

    revenueList: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.forecast.desc.revenueList;
        } else {
          return [
            { index: 0, text: '' },
            { index: 1, text: '' },
            { index: 2, text: '' },
            { index: 3, text: '' },
            { index: 4, text: '' },
          ];
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchForecastRevenueList', newValue);
      }
    },

     expns: { // Переключатель типа вводимой информации по расходам
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.forecast.desc.expnsSelected;
        } else {
          return 'expnsDesc';
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchForecastExpnsSelectedType', newValue);
      }
    },

    expnsDesc: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.forecast.desc.expnsDesc;
        } else {
          return '';
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchForecastExpnsDesc', newValue);
      }
    },

    expnsList: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.forecast.desc.expnsList;
        } else {
          return [
            { index: 0, text: '' },
            { index: 1, text: '' },
            { index: 2, text: '' },
            { index: 3, text: '' },
            { index: 4, text: '' },
          ];
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchForecastExpnsList', newValue);
      }
    },

    textCount() {
      return this.revenueDesc.length;
    }
  },

  methods: {
    revenueListFormatter(value) {
      return value.slice(0, 40)
    }
  }
  

}
</script>

<style lang="scss" scoped>

  .list-group-item {
    min-height: 2rem;
    .list-input {
      border: none;
      border-radius: 0;
      border-bottom: 1px dashed #344050;
      &:focus {
        outline: none !important;
        box-shadow: none;
        border-bottom: 1px solid #2c7be5 ;
      }
    }

    .list-input__count {
      position: absolute;
      right: 20px;
      top: 12px;
    }
 
  }

</style>>

