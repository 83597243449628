<template>
  <div class="">
      <h3 class="font-weight-light">Наименование компании и логотип</h3>
      <p>Название и логотип Вашей компании появится в верхней части документа. 
        Если у вас еще нет логотипа, ничего страшного. Презентация все равно будет хорошо смотреться даже без него.</p>
       <b-row class="mt-4">
          <b-col sm="6">
            <b-form-group
            class="mr-2" 
            label="Название Вашей компании"
            labele-for="incomeTitle" 
            :invalid-feedback="invalidFeedback"
            :valid-feedback="validFeedback"
            :state="titleState">
            <b-form-input
              id="incomeTitle"
              v-model="companyTitle"
              :state="titleState">
            </b-form-input>
            <small class="text-600">
              То же самое имя, которое отражается на титульной странице бизнес-плана
            </small>
          </b-form-group>
        </b-col>  
        <b-col sm="6">
          <b-form-group
            class="mr-2"
            label="Логотип Вашей компании">
            <b-form-file
              v-model="companyLogo"
              accept=".jpg, .png"
              :placeholder="imagePreview ? 'Изменить логотип...' : 'Выберите файл с логотипом...'"
              drop-placeholder="Оставьте файл здесь..."
              @input="setImagePreview">
            </b-form-file>
            <small>* - PNG или JPG, не более 2Mb</small>
          </b-form-group>
        </b-col>
        <b-col v-if="imagePreview" sm="8">
          <div class="bg-holder position-relative rounded-soft py-6 py-lg-8 overlay overlay-100">
            <div class="image-preview"
                :style="{
                  'width': '95%',
                  'left:': '10px',
                  'background-image': `url(${imagePreview})`,
                  'background-size': 'contain',
                }">
              </div> <!--/.bg-holder-->
          </div>
        </b-col>
      </b-row>
  </div>
</template>

<script>


export default {
   metaInfo: {

  },

  data: () => ({
    companyLogo: null,
    // imagePreview: null,
  }),

  components: {
  },

  mounted() {
  },

  computed: {
    companyTitle: {
      get: function() {
        var activeBPlan = this.$store.getters.getActiveBPlan;
        if (activeBPlan) {
          return activeBPlan.title;
        } else {
          return ''
        }
      },
      set: function(newValue) {
        this.$store.state.activeBPlan.title = newValue;
      }
    },

    imagePreview: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.company.logo;
        } else {
          return null;
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchCompanyLogo', newValue);
      }
    },

    titleState() {
      return this.companyTitle.length >= 4 ? true : false
    },

    invalidFeedback() {
      if (this.companyTitle.length >= 4) {
        return ''
      } else if (this.companyTitle.length > 0) {
        return 'Название должно быть не менее 4-х символов'
      } else {
        return 'Пожалуйства, введине название...'
      }
    },

    validFeedback() {
      return this.state === true ? 'Отлично!' : ''
    },
    
    

  },

  methods: {
    setImagePreview(file) {  
      if (!file) {
        return null;
      }

      if (file.type && file.type.indexOf('image') === -1) {
        this.companyLogo = null;
        this.imagePreview = null;
        return null
      } 
      const reader = new FileReader();
      reader.addEventListener('load', event => {
        this.imagePreview = event.target.result;
      });
      reader.readAsDataURL(file); 
    }

  }
  

}
</script>

<style lang="scss" scoped>
  .dropzone {
    background: inherit;
  }

  .image-preview {
    position: absolute;
    width: 95%;
    min-height: 98%;
    top: 1%;
    left: 2.5%;
    background-size: contain;
    background-position: center;
    overflow: hidden;
    will-change: transform, opacity, filter;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-repeat: no-repeat;
    z-index: 0;
  }
</style>>

