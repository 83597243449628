<template>
   <div>
    <b-modal
      id="competitorEdit" 
      header-class="pb-2"
      hide-footer
      no-stacking
      v-model="showModal">

      <template slot="modal-title">
        <div class="row mt-2">
            <div class="col text-left ml-2" style="min-width: 7rem">
              <h4 id="modalLabel" class="text-primary">
                Конкурент
                <small class="text-500">основные данные</small>
              </h4>
            </div>              
          </div>
      </template>

      <b-row class="mt-2">
          <b-col sm="12">
            <b-form-group
              class="ml-2 mr-2" 
              label="Наименование конкурента"
              labele-for="competitorTitle" 
              :invalid-feedback="invalidFeedback"
              :state="titleState">
              <b-form-input
                id="competitorTitle"
                v-model="competitor.title"
                :state="titleState"
                @click="displayTitleCount = true"
                @blur="displayTitleCount = false"
                :formatter="value => value.slice(0, 20)">
              </b-form-input>
              <span v-if="displayTitleCount"
                class="badge badge-soft-secondary counter">
                {{20 - competitor.title.length}}
              </span>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              class="ml-2 mr-2" 
              label="Чем Ваше предложение лучше?"
              labele-for="desc" 
              :invalid-feedback="descInvalidFeedback"
              :state="descState">
              <b-form-input
                id="desc"
                v-model="competitor.desc"
                :state="descState"
                @click="displayDescCount = true"
                @blur="displayDescCount = false"
                :formatter="value => value.slice(0, 40)">
              </b-form-input>
              <span v-if="displayDescCount"
                class="badge badge-soft-secondary counter">
                {{40 - competitor.desc.length}}
              </span>
            </b-form-group>
          </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col sm="12">
         
        </b-col>
      </b-row>
      
      <b-row >
        <b-col sm="12" class="d-flex">
          <b-button class="mt-3 mr-3 ml-2" @click.prevent="showModal = false">
            Отмена
          </b-button>
          <b-button 
            v-if="loadData"
            class="mt-3 mr-2"
            variant="primary"
            block 
            disabled>
            <b-spinner small type="grow"></b-spinner>
            Сохраняем...
          </b-button>
          <b-button 
            v-if="!loadData"
            class="mt-3 mr-2" 
            variant="primary" 
            block 
            @click="createCompetitor">
            Сохранить!
          </b-button>
        </b-col>
      </b-row>
      
    </b-modal>

  </div>
</template>

<script>

export default {
  data: () => ({
    showModal: false,
    displayTitleCount: false,
    displayDescCount: false,
    loadData: false,
  }),

  computed: {

    competitor() {
      const itemToEdit = this.$store.getters.getCompetitorToEdit;
      if (itemToEdit) {
        return itemToEdit
      } else {
        return { title: '', desc: '' }
      }
       
    },

    titleState() {
      if (this.competitor.title) {
        return this.competitor.title.length >= 4 ? true : false
      } else {
        return null
      }
      
    },

    descState() {
      if (this.competitor.desc) {
        return this.competitor.desc.length > 10 ? true : false
      } else {
        return null
      }
    },

    invalidFeedback() {
      if (this.competitor.title.length >= 4) {
        return ''
      } else if (this.competitor.title.length > 0) {
        return 'Название должно быть не менее 4-х символов'
      } else {
        return 'Пожалуйства, введине наименование...'
      }
    },

    descInvalidFeedback() {
      if (this.competitor.desc.length >= 10) {
        return ''
      } else if (this.competitor.desc.length > 0) {
        return 'Описание должно быть не менее 10-ти символов'
      } else {
        return 'Необходимо описание...'
      }
    },
  },

  methods: {
    
    createCompetitor() {
      
      if (!this.titleState || !this.descState) {
        return
      }

      this.$store.commit('setPitchCompetitorsListItem', this.competitor);
      this.showModal = false;
    },

  }

}
</script>

<style lang="scss" scoped>
  .income-title {
    line-height: 90%; 
    margin-top: 2px; 
    margin-bottom: 0.5rem;
  }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  .counter {
    position: absolute;
    right: 60px;
    top: 38px;
  }
</style>