<template>
   <div>
    <b-modal
      id="milestoneEditModal" 
      header-class="pb-2"
      hide-footer
      no-stacking
      v-model="showModal">

      <template slot="modal-title">
        <div class="row mt-2">
            <div class="col text-left ml-2" style="min-width: 7rem">
              <h4 id="modalLabel" class="text-primary">
                Ключевай этап
                <small class="text-500">информация</small>
              </h4>
            </div>              
          </div>
      </template>

      <b-row class="mt-2">
        <b-col sm="12">
          <b-form-group
            class="mx-2" 
            label="Название"
            labele-for="incomeTitle" 
            :invalid-feedback="invalidFeedback"
            :valid-feedback="validFeedback"
            :state="titleState">
            <b-form-input
              id="incomeTitle"
              v-model="milestoneTitle"
              :state="titleState">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="5" class="pr-0">
          <b-form-group
            class="ml-2" 
            label="Дедлайн"
            labele-for="incomeTitle">
            <b-form-datepicker 
              id="datepicker" 
              v-model="milestoneDedline" 
              class="mb-2"
              :state="deadlineState"
              today-button
              reset-button
              close-button
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              :start-weekday="1"
              locale="ru-RU"
              placeholder="---">
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col sm="7">
          <b-form-group
            class="mr-2" 
            label="Отвественный"
            labele-for="responsiblePerson">
            <b-form-input
              id="responsiblePerson"
              v-model="responsiblePerson">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            class="mx-2" 
            label="Описание:"
            labele-for="milestoneDesc">
            <b-form-textarea
              id="milestoneDesc"
              v-model="milestoneDesc">
            </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      
      <b-row >
        <b-col sm="12" class="d-flex">
          <b-button class="mt-3 mr-3 ml-2" @click.prevent="showModal = false">
            Отмена
          </b-button>
          <b-button 
            v-if="loadData"
            class="mt-3 mr-2"
            variant="primary"
            block 
            disabled>
            <b-spinner small type="grow"></b-spinner>
            Создаем...
          </b-button>
          <b-button 
            v-if="!loadData"
            class="mt-3 mr-2" 
            variant="primary" 
            block 
            @click="createMilestone">
            Создать!
          </b-button>
        </b-col>
      </b-row>
      
    </b-modal>

  </div>
</template>

<script>

export default {
  data: () => ({
    showModal: false,
    milestoneTitle: '',
    responsiblePerson: null,
    milestoneDesc: null,
    milestoneDedline: null,
    loadData: false,
  }),

  computed: {
    titleState() {
      return this.milestoneTitle.length >= 4 ? true : false
    },

    deadlineState() {
      return this.milestoneDedline ? true : false;
    },

    invalidFeedback() {
      if (this.milestoneTitle.length >= 4) {
        return ''
      } else if (this.milestoneTitle.length > 0) {
        return 'Название должно быть не менее 4-х символов'
      } else {
        return 'Пожалуйства, введине название...'
      }
    },

    validFeedback() {
      return this.titleState === true ? 'Отлично!' : ''
    },
  },

  methods: {
    async createMilestone() {
      this.loadData = true;

      if (!this.titleState || !this.deadlineState) {
        this.loadData = false
        return
      }

      const payload = {
        title: this.milestoneTitle,
        deadline: this.milestoneDedline,
        person: this.responsiblePerson,
        desc: this.milestoneDesc,
        finished: false
      }

      await this.$store.dispatch('createMilestone', payload);

      this.clearMilestoneData();
      this.loadData = false
    },

    clearMilestoneData() {
        this.milestoneTitle = '';
        this.milestoneDedline = null;
        this.responsiblePerson = null;
        this.milestoneDesc = null;
        this.showModal = false;
    }

  }

}
</script>

<style lang="scss" scoped>
  .income-title {
    line-height: 90%; 
    margin-top: 2px; 
    margin-bottom: 0.5rem;
  }
  .income-option {
    padding: 0 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: rgba(14, 187, 245, 0.10);
    }
    &.selected {
      background: rgba(14, 187, 245, 0.10);
    }
  }
</style>