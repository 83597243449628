<template>
  <div class="">
    <div class="d-flex ">
      <h3 class="font-weight-light mb-3">Внешнее финансирование</h3>
      <b-form-checkbox
        id="needFinance"
        class="ml-auto mt-2 mr-2"
        v-model="needFinance"
        name="checkbox-1"
        :value="false"
        :unchecked-value="true">
        Скрыть раздел
      </b-form-checkbox>
    </div>
    <p>
      Если Вы планируете привлекать внешнее финансирование (инвестиции), укажите сумму, которую планируете привлечь, и опишите, 
      как и на что планируется использовать эти деньги.
    </p>
    <p>
      Если Вам не требуется внешнее финансирование, то можете скрыть этот раздел, установив соответсвующую галочку.
    </p>

    <b-row class="mt-4">
      <b-col sm="12">
        <label class="ml-1"
          for="investments">
          Сумма привлекаемых инвестиций:
        </label>
      </b-col>
      <b-col sm="3">
        <b-input-group
          class="mt-1"   
          append="₽">
          <b-form-input 
            id="investments"
            v-model="amount"
            class="text-right"
            type="number">
          </b-form-input>
        </b-input-group>
      </b-col>
      <b-col 
        sm="12"
        class="mt-2">
        <label for="description" class="ml-1 mt-3">
          <strong>Для чего привлекаются средства:</strong>
        </label>
        <b-form-textarea
          id="description"
          v-model="desc"
          placeholder="Коротко и понятно..."
          :state="(desc.length > 140 ? false : null)"
          rows="4"
          max-rows="3">
        </b-form-textarea>
        <small class="text-300" style="position: relative; left: 1.2rem; top: -1.5rem;">
          {{textCount}} из 140 символов
        </small>
      </b-col>
     
    </b-row>
  </div>
</template>

<script>

export default {
   metaInfo: {

  },

  data: () => ({
  }),

  computed: {

    amount: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.investments.amount;
        } else {
          return 0;
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchInvestmentsAmount', newValue);
      }
    },

    desc: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.investments.desc;
        } else {
          return '';
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchInvestmentsDesc', newValue);
      }
    },

    needFinance: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.investments.needFinance;
        } else {
          return true;
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchInvestmentsNeedFinance', newValue);
      }
    },

    textCount() {
      return this.desc.length;
    }
  },

  methods: {
    formatter(value) {
      return value.slice(0, 40)
    }
  }
  

}
</script>

<style lang="scss" scoped>

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

</style>>

