<template>
  <div class="pl-4 mt-2">
    <component :is="activePage" />
  </div>
</template>

<script>
import BuilderCompany from './pitch/edit/Company';
import BuilderDescription from './pitch/edit/Description';
import BuilderProblem from './pitch/edit/Problem';
import BuilderSolution from './pitch/edit/Solution';
import BuilderMarket from './pitch/edit/Market';
import BuilderCompetitors from './pitch/edit/Competitors';
import BuilderInvestments from './pitch/edit/Investments';
import BuilderSales from './pitch/edit/Sales';
import BuilderMarketing from './pitch/edit/Marketing';
import BuilderForecast from './pitch/edit/Forecast';
import BuilderMilestones from './pitch/edit/Milestones';
import BuilderTeam from './pitch/edit/Team';
import BuilderResources from './pitch/edit/Resources';

export default {
  data: () => ({
  }),

  components: {
    BuilderCompany,
    BuilderDescription,
    BuilderProblem,
    BuilderSolution,
    BuilderMarket,
    BuilderCompetitors,
    BuilderInvestments,
    BuilderSales,
    BuilderMarketing,
    BuilderForecast,
    BuilderMilestones,
    BuilderTeam,
    BuilderResources
  }, 

  beforeMount() {
    const pitch = this.$store.getters.getPitch;
    if (!pitch) {
      this.$store.dispatch('fetchPitch');
    }
  },

  computed: {
    activePage() {
      var page = 'company';
      var defaultPage = 'builder-company';
      
      if (this.$route.query.builder) {
        page = 'Builder' + page.charAt(0).toUpperCase() + page.slice(1);
      }

      if (page in this.$options.components) {
        return `builder-${this.$route.query.builder}`;
      } else {
        return defaultPage
      }

      
    }
  },

  methods: {
    
  }

}
</script>

<style>

</style>