<template>
  <div class="">
    <h3 class="font-weight-light mb-3">Партнеры и Ресурсы</h3>
    <p>
      Расскажите о Ваших ресурсах: заключенные соглашения, оборудование, объекты недвижимости, патенты и т.д., 
      все, что стратегически важно для Вашей бизнес-модели. 
    </p>

    <b-row class="mt-4">
      <b-col sm="12" class="mb-3">
        <div class="card mb-3">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-5">
                  <strong>Ключевые ресурсы</strong>
                </div>

                <div class="col-auto ml-auto">
                  <div v-b-modal.pitchResourceEdit
                    class="btn btn-outline-primary btn-sm"
                    @click="newResource">
                    <svg class="svg-inline--fa fa-plus fa-w-14 mr-1" 
                      data-fa-transform="shrink-3" 
                      aria-hidden="true" 
                      focusable="false" 
                      data-prefix="fas" 
                      data-icon="plus" 
                      role="img" 
                      xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 448 512" 
                      data-fa-i2svg="" 
                      style="transform-origin: 0.4375em 0.5em;">
                      <g transform="translate(224 256)">
                        <g transform="translate(0, 0)  scale(0.8125, 0.8125)  rotate(0 0 0)">
                          <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" transform="translate(-224 -256)">
                          </path>
                        </g>
                      </g>
                    </svg>
                    Добавить
                  </div>
                </div>
              </div>
            </div>
            <draggable 
              class="card-body fs--1 border-top border-200 p-0" id="competitors"
              :list="list"
              @change="moveItems">
              <div v-for="(item, index) in list" :key="item.index" 
                class="row border-bottom border-200 hover-actions-trigger hover-shadow py-2 px-1 mx-0">
                <div class="btn-group btn-group-sm hover-actions r-0 mr-3">
                  <button class="btn btn-outline-light border-0" 
                    type="button" 
                    data-toggle="tooltip" 
                    data-placement="top" 
                    title="" 
                    data-original-title="Редактировать"
                    v-b-modal.pitchResourceEdit
                    @click="editResource(item)">
                    <span class="fas fa-pencil-alt"></span>
                  </button>
                  <button class="btn btn-outline-danger border-0" 
                    type="button" 
                    data-toggle="tooltip" 
                    data-placement="top" 
                    title="" 
                    data-original-title="Удалить"
                    @click="deleteResource(index)">
                    <svg class="svg-inline--fa fa-trash-alt fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path>
                    </svg>
                    <!-- <span class="fas fa-trash-alt"></span> -->
                  </button>
                </div>
                <div class="col-3">
                  <div class="avatar image">
                    <img :src="item.image" alt="">
                  </div>
                </div>
                <div class="col-3 d-flex">
                  <div class="btn-sm px-2 border-0 hover-actions move-arrow text-300"
                    style="position: absolute; left: -20px;">
                    <i class="fas fa-arrows-alt-v"></i>
                  </div>
                  <div class="align-self-center">
                    <span class="fs-0">
                      {{item.title}}
                    </span>
                  </div>
                </div>   
                <div class="col-6 d-flex">
                  <span class="align-self-center">{{item.desc}}</span>
                </div>
              </div>
            </draggable>
            
          </div>
      </b-col>

    </b-row>
    
    <!-- EDIT MODAL  -->
    <resource-edit-modal />

  </div>
</template>

<script>
import draggable from "vuedraggable"

import resourceEditModal from '@/components/app/modals/PitchResourceEdit';

export default {
   metaInfo: {

  },

  data: () => ({
    displayCount: null
  }),

  components: {
    draggable,
    resourceEditModal
  },

  computed: {

    list: {
      get: function() {
        const pitch = this.$store.getters.getPitch;
        if (pitch) {
          return pitch.resources.list;
        } else {
          return [];
        }
      },
      set: function(newValue) {
        this.$store.commit('addPitchResourcesListItem', newValue);
      }
    },

    textCount() {
      return this.desc.length;
    }
  },

  methods: {
    
    moveItems() {
      this.list.map((item, index) => {
        item.index = index;
      });
    },

    newResource() {
      this.editResource(null);
    },

    editResource(resource) {
      this.$store.commit('setPitchResourceToEdit', resource);
    },

    deleteResource(index) {
      this.$store.commit('deletePitchResourcesListItem', index);
    }
  }
  

}
</script>

<style lang="scss" scoped>
.image {
  height: 6.125rem;
  width: 10rem;
}

</style>>

